import React, { useRef, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Footer from '../universal/footer';
import './services.css'
import '../universal/buttons.css'
import useDelayedHeightCalculation from '../universal/useDelayedHeightCalculation';

function Services({ navbarHeight }) {
  const section1Ref = useRef(null);
  const [bgHeight, setBgHeight] = useState(0);

  const section1Height = useDelayedHeightCalculation(section1Ref, 100); // 100ms delay, you can adjust this

  useEffect(()=> {
    setBgHeight(section1Height + navbarHeight)
  }, [navbarHeight, section1Height]);

  return (
    <div className='main-content'>
      <img src='./assets/images/backgrounds/bg1.png'
      className='services-section1-bg'
      style={{ height: `${bgHeight}px` }}
      alt="Background"/>

      <div className='services-section1' ref={section1Ref}>
        <h1>SERVICES</h1>
        <div className='services-categories'>
          <div className='category category1'>
            <h1>COPY TRADING</h1>
            <p>Sign up on BINGX with our link and copy CRYPTOXNFT and other professional traders!</p>
            <Link to='/services/copy-trading'><button className='button-dark'>COPY TRADING</button></Link>
          </div>
          <div className='category category2'>
            <h1>VIP GROUP</h1>
            <p>Receive comprehensive analysis and timely updates directly in your Telegram inbox.</p>
            <Link to='/services/vip'><button className='button-dark'>VIP GROUP</button></Link>
          </div>
          <div className='category category3'>
            <h1>INDICATOR</h1>
            <p>State-of-the-art AI indicator. TA-optimized for any pair and timeframe.</p>
            <Link to='/services/indicator'><button className='button-dark'>INDICATOR</button></Link>
          </div>
        </div> 
      </div>

      <div className='services-section2'>
        <h1>WE ARE ALL YOU NEED IN 2024</h1>

        <div className='cat'>
          <h2>COPY TRADING</h2>
          <div className='cat-content'>
            <div className='cat-content1'>
              <img src='./assets/images/services/tradingData.jpg'/>
            </div>
            <div className='cat-content2'>
              <div className='cat-content2-text'>
                <p>All you need to do is set up a BINGX account using our referral link and follow the instructions to copy our professional trading partners!</p>
                <p className='p2'><span>103% RETURN IN 180 DAYS!</span> You can't say no to such an offer!</p>
                <Link to='/services/copy-trading'><button className='button-dark button-desktop'>COPY TRADING</button></Link>
              </div>
            </div>
          </div>
        </div>

        <div className='cat desktop'>
          <h2>VIP GROUP</h2>
          <div className='cat-content'>
            <div className='cat-content1'>
              <div className='cat-content2-text'>
                <p>Gain immediate access to our in-depth analysis and <span>TOP TIER SIGNALS</span> by subscribing to our VIP Membership Telegram channels. Information is <span>SHARED INSTANTENIOUSLY</span>, allowing clients worldwide to get maximum value from our valuable insights.</p>
                <p className='p2'>Receive comprehensive analysis and timely updates directly in your Telegram inbox.</p>
                <Link to='/services/vip'><button className='button-dark'>VIP GROUP</button></Link>
              </div>
            </div>
            <div className='cat-content2'>
              <div className='cat-copytading-images'>
                <img className='image-one' src='./assets/images/services/pnl2.png'/>
                <img className='image-two' src='./assets/images/services/pnl1.png'/>
              </div> 
            </div>
          </div>
        </div>

        <div className='cat mobile'>
          <h2>VIP GROUP</h2>
          <div className='cat-content'>
            <div className='cat-content1'>
              <div className='cat-copytading-images'>
                <img className='image-one' src='./assets/images/services/pnl2.png'/>
                <img className='image-two' src='./assets/images/services/pnl1.png'/>
              </div>
              
            </div>
            <div className='cat-content2'>
              <div className='cat-content2-text'>
                <p>Gain immediate access to our in-depth analysis and <span>TOP TIER SIGNALS</span> by subscribing to our VIP Membership Telegram channels. Information is <span>SHARED INSTANTENIOUSLY</span>, allowing clients worldwide to get maximum value from our valuable insights.</p>
                <p className='p2'>Receive comprehensive analysis and timely updates directly in your Telegram inbox.</p>
                <Link to='/services/vip'><button className='button-dark'>VIP GROUP</button></Link>
              </div>
            </div>
          </div>
        </div>

        <div className='cat'>
          <h2>AI INDICATOR</h2>
          <div className='cat-content'>
            <div className='cat-content1'>
              <img src='./assets/images/services/indicator.png'/>
            </div>
            <div className='cat-content2'>
              <div className='cat-content2-text'>
                <p>We have developed a state-of-the-art AI indicator. It's a <span>TA-optimized</span> algorithm so you can use it on <span>ANY PAIR</span> and <span>ANY TIMEFRAME.</span> We are even backtesting it on forex, stocks and other indices. Rest assured, crypto remains a priority with most strategies optimized for crypto trading.</p>
                <p className='p2'><span>TA-optimized & AI powered!</span> ANY PAIR & ANY TIMEFRAME</p>
                <Link to='/services/indicator'><button className='button-dark'>INDICATOR</button></Link>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className='footer'><Footer/></div>
    </div>
  );
}

export default Services;
