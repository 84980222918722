import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Home from '../pages/home/Home';
import Services from '../pages/services/services';

import VIP from '../pages/services/vip/vip';
import Payment from '../pages/services/vip/payment/payment';

import INDICATOR from '../pages/services/indicator/indicator';
import IndicatorPayment from '../pages/services/indicator/payment/indicatorPayment';

import COPY from '../pages/services/copy-trading/copyTrading';
import GUIDE from '../pages/services/copy-trading/guide/copy-trading-guide';
import BlofinGuide from '../pages/affiliates/blofin/blofinguide';

import About from '../pages/about/About';
import Contact from '../pages/contact/Contact';
import FAQ from '../pages/faq/faq';

import Terms from '../pages/terms&conditions/terms';
import Risk from '../pages/risk-disclosure/risk';

import TradersNotes from '../pages/affiliates/tradersnotes/tradersnotes';

import BitcoinOg from '../pages/affiliates/blofinAff/bitcoin_og';
import EthereumUs from '../pages/affiliates/blofinAff/ethereum_us';
import CryptoPages from '../pages/affiliates/blofinAff/cryptoPages';
import AltcoinCryptos from '../pages/affiliates/blofinAff/altcoinCryptos';
import NftGlobally from '../pages/affiliates/blofinAff/nftGlobally';
import SolanaCoins from '../pages/affiliates/blofinAff/solanaCoins';
import TrxCryptos from '../pages/affiliates/blofinAff/trxCryptos';
import XrpCryptos from '../pages/affiliates/blofinAff/xrpCryptos';

import './app.css';

function App() {
  const navbarRef = useRef(null);
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.offsetHeight);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <div ref={navbarRef}>
          <Navbar />
        </div>
        <Routes>
          <Route path="/" element={<Home navbarHeight={navbarHeight} />} />
          <Route path='/services' element={<Services navbarHeight={navbarHeight}/>}/>
          <Route path='/services/vip' element={<VIP navbarHeight={navbarHeight}/>}/>
          <Route path='/services/vip/payment' element={<Payment navbarHeight={navbarHeight}/>}/>
          <Route path='/services/copy-trading' element={<COPY navbarHeight={navbarHeight}/>}/>
          <Route path='/services/copy-trading/guide' element={<GUIDE navbarHeight={navbarHeight}/>}/>
          <Route path='/blofin-guide' element={<BlofinGuide navbarHeight={navbarHeight}/>}/>
          <Route path='/services/indicator' element={<INDICATOR navbarHeight={navbarHeight}/>}/>
          <Route path='/services/indicator/payment' element={<IndicatorPayment navbarHeight={navbarHeight}/>}/>
          <Route path='/about' element={<About navbarHeight={navbarHeight}/>} />
          <Route path='/contact' element={<Contact navbarHeight={navbarHeight}/>} />
          <Route path='/faq' element={<FAQ navbarHeight={navbarHeight}/>}/>
          <Route path='/terms&conditions' element={<Terms/>}/>
          <Route path='/risk-disclosure' element={<Risk navbarHeight={navbarHeight}/>}/>

          {/*AFFILIATES*/}

          <Route path='/tradersnotes' element={<TradersNotes navbarHeight={navbarHeight}/>}/>

          <Route path='/affiliate/bitcoin_og' element={<BitcoinOg navbarHeight={navbarHeight}/>}/>
          <Route path='/affiliate/ethereum_us' element={<EthereumUs navbarHeight={navbarHeight}/>}/>
          <Route path='/affiliate/crypto_pages' element={<CryptoPages navbarHeight={navbarHeight}/>}/>
          <Route path='/affiliate/altcoin_cryptos' element={<AltcoinCryptos navbarHeight={navbarHeight}/>}/>
          <Route path='/affiliate/nft_globally' element={<NftGlobally navbarHeight={navbarHeight}/>}/>
          <Route path='/affiliate/solana_coins' element={<SolanaCoins navbarHeight={navbarHeight}/>}/>
          <Route path='/affiliate/trx_cryptos' element={<TrxCryptos navbarHeight={navbarHeight}/>}/>
          <Route path='/affiliate/xrp_cryptos' element={<XrpCryptos navbarHeight={navbarHeight}/>}/>

        </Routes>
      </div>
    </Router>
  );
}

export default App;
