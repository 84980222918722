import React, {useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import './vip.css'
import Footer from '../../universal/footer'
import '../../universal/buttons.css'
import useDelayedHeightCalculation from "../../universal/useDelayedHeightCalculation";
import Timer from "../../universal/timer";

const VIP = ({navbarHeight}) => {

    const section1Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);
  
    const section1Height = useDelayedHeightCalculation(section1Ref, 100); // 100ms delay, you can adjust this

    useEffect(()=> {
        setBgHeight(section1Height + navbarHeight)

    }, [navbarHeight, section1Height]);

    return(
        <div className="main-content">
        <img src='.././assets/images/backgrounds/bg1.png'
            className='vip-section1-bg'
            style={{ height: `${bgHeight}px` }}
            alt="Background"/>

            <div className="vip-section1" ref={section1Ref}>
                <h1>VIP GROUP</h1>
                <h2 className="vip-section1-subheader">LIMITED <span style={{color: '#c80170'}}>70% DISCOUNT</span></h2>
                <div className="timer"><Timer/></div>
                <div className="vip-pricing">
                    <div className="vip-monthly">
                        <div className="vip-top">
                            <h2>VIP MEMBERSHIP</h2>
                        </div>
                        <div className="vip-price">
                            <p className="vip-card-text">DAILY SIGNALS</p>
                            <p className="vip-card-text">ECONOMIC UPDATES</p>
                            <p className="vip-card-text-last">TECHNICAL ANALYSIS</p>
                            <Link to="/services/vip/payment"><button className="button-vip">BUY NOW</button></Link>
                        </div>
                    </div>
                </div>
                <div className="vip-free-telegram">
                    <a href="https://t.me/cryptoxnfts" target="blank"><button className="button-light">FREE GROUP</button></a>
                </div>
                <div className="vip-images">
                    <img src=".././assets/images/services/vip/pnl1.png" alt="pnl1"/>
                    <img src=".././assets/images/services/vip/pnl2.png" alt="pnl2" className="vip-image-middle"/>
                    <img src=".././assets/images/services/vip/pnl3.png" alt="pnl3"/>               
                </div>
            </div>

            <div className="vip-section2">
                <h1>NOT SURE WHAT TO EXPECT?</h1>
                <h2>TAKE A LOOK INSIDE OUR VIP TELEGRAM GROUP</h2>
                <div className="vip-section2-vids">
                    <video src=".././assets/images/services/vip/vid1.MOV" loop={true} muted={true} autoPlay={true}/>
                    <video src=".././assets/images/services/vip/vid2.MOV" loop={true} muted={true} autoPlay={true}/>
                </div>
            </div>

            <div className="footer"><Footer/></div>
        </div>
    )
}

export default VIP;