import React, { useState, useEffect, useRef } from 'react';
import Footer from '../../../universal/footer';
import './copy-trading-guide.css';

const GUIDE = ({ navbarHeight }) => {
    const section1Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);

    // Define traders with their names, links, and probabilities
    const traders = [
        { name: 'CRYPTOXNFT', link: 'https://general.bingx.com/39S6z3', probability: 1 },
        { name: 'YXI', link: 'https://bingx.com/int/2KvcWy', probability: 0 },
        { name: 'Anastasia1911919', link: 'https://bingx.com/int/0oW1YG', probability: 0 }
    ];

    // Function to select a trader based on probabilities
    const selectTrader = () => {
        const randomValue = Math.random();
        let cumulativeProbability = 0;

        for (const trader of traders) {
            cumulativeProbability += trader.probability;
            if (randomValue < cumulativeProbability) {
                return trader;
            }
        }
    };

    useEffect(() => {
        if (section1Ref.current) {
            const section1Height = section1Ref.current.offsetHeight;
            setBgHeight(section1Height + navbarHeight);
        }

        // Select a trader on page load
        const selectedTrader = selectTrader();

        // Update the trader name and link
        const traderNameElement = document.getElementById('trader-name');
        const traderLinkElement = document.getElementById('trader-link');

        if (traderNameElement && traderLinkElement) {
            traderNameElement.textContent = selectedTrader.name;
            traderLinkElement.href = selectedTrader.link;

            // Handle the text after the trader name
            const traderNameBox = traderNameElement.parentNode;

            if (selectedTrader.name === 'CRYPTOXNFT') {
                // If the trader is CRYPTOXNFT, add "TEAM" after the name
                traderNameBox.innerHTML = `<span id='trader-name' style='color: #0147ff'>${selectedTrader.name}</span> <span style='color: #000'>TEAM</span>`;
            } else {
                // For other traders, add "X CRYPTOXNFT" with styling
                traderNameBox.innerHTML = `
                    <span id='trader-name' style='color: #0147ff'>${selectedTrader.name}</span> 
                    <span style='color: #000'>X </span>
                    <span style='color: #0147ff'>CRYPTOXNFT</span>
                `;
            }
        }
    }, [navbarHeight]);

    return (
        <div className='main-content'>
            <img
                src='../.././assets/images/backgrounds/bg1.png'
                className='copy-section1-bg'
                style={{ height: `${bgHeight}px` }}
                alt="Background"
            />
            <div className='guide-main'>
                <div className='guide-section1' ref={section1Ref}>
                    <h1>COPY TRADING GUIDE</h1>
                    <h2>LET OTHERS DO THE WORK FOR YOU</h2>
                    <div className='trader-name-box'>
                        <h2 className='trader-name'>
                            <span id='trader-name' style={{ color: '#0147ff' }}>Loading...</span> TEAM
                        </h2>
                    </div>
                    <h3>FOLLOW THE STEPS BELOW, DEPOSIT ANY AMOUNT OF MONEY AND EXPECT AROUND <span>2X - 3X</span> IN <span>20 - 30 DAYS</span></h3>
                </div>

                <div className='guide-section2'>
                    <div className="guide-section2-steps">
                        <div className="copy-step">
                            <h2 className="copy-steps-header">STEP 1</h2>
                            <h3>Click the button below</h3>
                            <a id='trader-link' href='#' target='_blank'>
                                <button>JOIN</button>
                            </a>
                        </div>
                        <div className="copy-step">
                            <h2 className="copy-steps-header">STEP 2</h2>
                            <h3>Click "Copy Now"</h3>
                            <img src='../.././assets/images/services/copy/guide/step2.png' className='copy-step-image' alt="Step 2"/>
                        </div>
                        <div className="copy-step">
                            <h2 className="copy-steps-header">STEP 3</h2>
                            <h3>Set Copy By to "Per Order"</h3>
                            <img src='../.././assets/images/services/copy/guide/step3edited.png' className='copy-step-image' alt="Step 3"/>
                        </div>
                        <div className="copy-step">
                            <h2 className="copy-steps-header">STEP 4</h2>
                            <h3>Enter the USDT amount for copying</h3>
                            <img src='../.././assets/images/services/copy/guide/step4.png' className='copy-step-image' alt="Step 4"/>
                        </div>
                        <div className="copy-step">
                            <h2 className="copy-steps-header">STEP 5</h2>
                            <h3>Accept the ToS</h3>
                            <img src='../.././assets/images/services/copy/guide/step5.png' className='copy-step-image' alt="Step 5"/>
                        </div>
                        <div className="copy-step">
                            <h2 className="copy-steps-header">STEP 6</h2>
                            <h3>Click "Copy Now"</h3>
                            <img src='../.././assets/images/services/copy/guide/step6.png' className='copy-step-image' alt="Step 6"/>
                        </div>
                    </div>
                    <h1 style={{color: '#000', fontSize:'7vw', width:'70vw'}}>CONGRATULATIONS YOU ARE ALL DONE!</h1>
                </div>
                <div className='footer'><Footer/></div>
            </div>
        </div>
    );
};

export default GUIDE;
