import React, { useState, useEffect } from 'react';

const Timer = () => {
    const calculateTimeLeft = () => {
        // Get the current time
        const now = new Date();
        
        // Calculate next midnight
        const nextMidnight = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1, // Tomorrow
            0, 0, 0, 0 // Midnight
        );

        // Calculate the difference in milliseconds
        const timeDifference = nextMidnight - now;

        // Convert milliseconds to seconds
        return Math.floor(timeDifference / 1000); // Return as seconds
    };

    // State for time left until the next midnight
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        // Function to update the timer every second
        const tick = () => {
            setTimeLeft(prevTime => {
                if (prevTime <= 0) {
                    return calculateTimeLeft(); // Reset the time at midnight
                }
                return prevTime - 1; // Decrease by 1 second
            });
        };

        // Set an interval to call `tick` every second
        const timerId = setInterval(tick, 1000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(timerId);
    }, []);

    // Convert timeLeft (in seconds) to hours, minutes, and seconds
    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    // Format the timer with leading zeros
    const formatTime = (time) => (time < 10 ? `0${time}` : time);

    return (
        <div>
            <div style={{ fontSize: '4em', color: '#fff', fontWeight: 'bold', marginBlock: '2vh'}}>
                {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}
            </div>
        </div>
    );
};

export default Timer;
